import React, { useEffect, useState } from "react";
import "./main_dashboard.css";
import Top_navbar from "./Top_navbar";
import { IoCompassOutline, IoStar } from "react-icons/io5";
import { IoStarHalfOutline } from "react-icons/io5";
import Recent_order from "./Recent_order";
import { Select, Skeleton } from "antd";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import { BASE_URL } from "../../config/constants";
import { getDashboardData } from "../../controllers/accounts/Account";
import TransactionList from "./TransactionList";

const Dashboard = () => {
  const [SALES, setSales] = useState("");
  const [salesText, setSalesText] = useState("Total");
  const [REVENUE, setRevenue] = useState("");
  const [revenueText, setRevenueText] = useState("Total");
  const [TRANSACTION, setTransaction] = useState("");
  const [transactionText, setTransactionText] = useState("Shop");

  const getData = async () => {
    try {
      setSales("");
      setSales("");
      setRevenue("");
      setTransaction("");
      const res = await getDashboardData();
      if (res.status === true) {
        setSales(res?.result?.sales);
        setRevenue(res?.result?.revenue);
        setTransaction(res?.result?.transactions);
      } else {
        setSales("");
        setRevenue("");
        setTransaction("");
      }
    } catch (error) {
      setSales("");
      setSales("");
      setRevenue("");
      setTransaction("");
    }
  };

  const handleSalesChange = (value, option) => {
    try {
      // console.log('option',option);
      setSalesText(value);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRevenueChange = (value, option) => {
    try {
      // console.log('option',option);
      setRevenueText(value);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleTransactionChange = (value, option) => {
    try {
      // console.log('option',option);
      setTransactionText(value);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
    document.title = "Frisbee Store || Dashboard";
  }, []);

  /*********************************************************
   * This function is load to scrolls the window to the top-left corner of the document
   *********************************************************/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Top_navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 px-0">
            <div className="transaction_form">
              <div className="transaction_Card margin-right">
                <div className="transction_main_Card ">
                  <h1>Total Sales</h1>
                  <div>
                    <Select
                      defaultValue={salesText}
                      className="select_filter_class"
                      onChange={handleSalesChange}
                      name="sales"
                      style={{
                        width: 120,
                      }}
                      bordered={false}
                      options={[
                        {
                          value: "Total",
                          label: "Total",
                        },
                        {
                          value: "Shop",
                          label: "Shop",
                        },
                        {
                          value: "Grooming",
                          label: "Grooming",
                        },
                        {
                          value: "Boarding",
                          label: "Boarding",
                        },
                        {
                          value: "Veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "Training",
                          label: "Training",
                        },
                        {
                          value: "Day Care",
                          label: "Day Care",
                        },
                      ]}
                    />
                  </div>
                </div>

                <p className="presentation">
                  This represents total Sales in <br></br> past 6 months.
                </p>
                <>
                  {SALES.length === 0 ? (
                    <Skeleton loading={true} active paragraph={{ rows: 0 }}>
                      <p className="sales_text"></p>
                    </Skeleton>
                  ) : (
                    <>
                      {salesText === "Total" && (
                        <p className="sales_text">{`AED ${SALES?.totalSales?.toFixed(2)}`}</p>
                      )}
                      {salesText === "Shop" && (
                        <p className="sales_text">{`AED ${SALES?.totalShopSales?.toFixed(2)}`}</p>
                      )}
                      {salesText === "Grooming" && (
                        <p className="sales_text">{`AED ${SALES?.totalGroomingSales?.toFixed(2)}`}</p>
                      )}
                      {salesText === "Training" && (
                        <p className="sales_text">{`AED ${SALES?.totalTrainingSales?.toFixed(2)}`}</p>
                      )}
                      {salesText === "Veterinary" && (
                        <p className="sales_text">{`AED ${SALES?.totalVeterinarySales?.toFixed(2)}`}</p>
                      )}
                      {salesText === "Boarding" && (
                        <p className="sales_text">{`AED ${SALES?.totalBoardingSales?.toFixed(2)}`}</p>
                      )}
                      {salesText === "Day Care" && (
                        <p className="sales_text">{`AED ${SALES?.totalDayCareSales?.toFixed(2)}`}</p>
                      )}
                    </>
                  )}
                </>
              </div>
              <div className="transaction_Card">
                <div className="transction_main_Card">
                  <h1>Total Revenue</h1>
                  <div>
                    <Select
                      defaultValue={revenueText}
                      className="select_filter_class"
                      onChange={handleRevenueChange}
                      name="sales"
                      style={{
                        width: 120,
                      }}
                      bordered={false}
                      options={[
                        {
                          value: "Total",
                          label: "Total",
                        },
                        {
                          value: "Shop",
                          label: "Shop",
                        },
                        {
                          value: "Grooming",
                          label: "Grooming",
                        },
                        {
                          value: "Boarding",
                          label: "Boarding",
                        },
                        {
                          value: "Veterinary",
                          label: "Veterinary",
                        },
                        {
                          value: "Training",
                          label: "Training",
                        },
                        {
                          value: "Day Care",
                          label: "Day Care",
                        },
                      ]}
                    />
                  </div>
                </div>
                <p className="presentation">
                  This represents total Sales in <br></br> past 6 months.
                </p>
                <>
                  {SALES.length === 0 ? (
                    <Skeleton loading={true} active paragraph={{ rows: 0 }}>
                      <p className="sales_text"></p>
                    </Skeleton>
                  ) : (
                    <>
                      {revenueText === "Total" && (
                        <p className="sales_text">{`AED ${(
                          REVENUE?.totalRevenue ?? 0
                        ).toFixed(2)}`}</p>
                      )}
                      {revenueText === "Shop" && (
                        <p className="sales_text">{`AED ${(
                          REVENUE?.totalShopRevenue ?? 0
                        ).toFixed(2)}`}</p>
                      )}
                      {revenueText === "Grooming" && (
                        <p className="sales_text">{`AED ${(
                          REVENUE?.totalGroomingRevenue ?? 0
                        ).toFixed(2)}`}</p>
                      )}
                      {revenueText === "Training" && (
                        <p className="sales_text">{`AED ${(
                          REVENUE?.totalTrainingRevenue ?? 0
                        ).toFixed(2)}`}</p>
                      )}
                      {revenueText === "Veterinary" && (
                        <p className="sales_text">{`AED ${(
                          REVENUE?.totalVeterinaryRevenue ?? 0
                        ).toFixed(2)}`}</p>
                      )}
                      {revenueText === "Boarding" && (
                        <p className="sales_text">{`AED ${(
                          REVENUE?.totalBoardingRevenue ?? 0
                        ).toFixed(2)}`}</p>
                      )}
                      {revenueText === "Day Care" && (
                        <p className="sales_text">{`AED ${(
                          REVENUE?.totalDayCareRevenue ?? 0
                        ).toFixed(2)}`}</p>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>

            
          </div>

          <div className="col-md-5">
            <div className="transaction_Card">
              <div className="transction_main_Card">
                <h1>Transactions</h1>
                <div>
                  <Select
                    defaultValue={transactionText}
                    onChange={handleTransactionChange}
                    style={{
                      width: 100,
                    }}
                    bordered={false}
                    options={[
                      {
                        value: "Shop",
                        label: "Shop",
                      },
                      {
                        value: "Grooming",
                        label: "Grooming",
                      },
                      {
                        value: "Boarding",
                        label: "Boarding",
                      },
                      {
                        value: "Veterinary",
                        label: "Veterinary",
                      },
                      {
                        value: "Training",
                        label: "Training",
                      },
                      {
                        value: "Day Care",
                        label: "Day Care",
                      },
                    ]}
                  />
                </div>
              </div>
              <ul>
                {transactionText === "Shop" && (
                  <TransactionList transactions={TRANSACTION?.shop} />
                )}
                {transactionText === "Grooming" && (
                  <TransactionList transactions={TRANSACTION?.grooming} />
                )}
                {transactionText === "Boarding" && (
                  <TransactionList transactions={TRANSACTION?.boarding} />
                )}
                {transactionText === "Training" && (
                  <TransactionList transactions={TRANSACTION?.training} />
                )}
                {transactionText === "Veterinary" && (
                  <TransactionList transactions={TRANSACTION?.veterinary} />
                )}
                {transactionText === "Day Care" && (
                  <TransactionList transactions={TRANSACTION?.dayCare} />
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* tables */}
        <div className="row">
          <div className="col-md-12 inventory_tabs_main_div_card">
            <Recent_order></Recent_order>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
